/* eslint-disable max-len */
import * as React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import CloseIcon from './icons/Close';
import { PhoneLink } from './PhoneLink';
import s from './MobileMenu.module.css';

type Props = {
  readonly shown: boolean;
  readonly onClose: () => void;
};

export const MobileMenu = ({ shown, onClose }: Props) => (
  <>
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
    <div
      onClick={onClose}
      className={cn(
        'hidden fixed inset-0 z-10 transition-opacity duration-300 opacity-0 pointer-events-none msm:block',
        shown && 'opacity-100 pointer-events-auto',
      )}
      style={{ backgroundColor: 'rgba(92, 92, 92, 0.8)' }}
    />
    <div className={cn(s.root, shown && s.shown)}>
      <div className={s.inner}>
        <button type="button" onClick={onClose} className={s.closeButton}>
          <CloseIcon className="w-6 h-6" />
        </button>
        <Link to="/catalog" className="text-2xl font-semibold leading-8 mb-8">
          Каталог
        </Link>
        <Link to="/delivery" className="text-2xl font-semibold leading-8 mb-8">
          Доставка
        </Link>
        <Link to="/contacts" className="text-2xl font-semibold leading-8 mb-auto">
          Контакты
        </Link>
        <div className="leading-4">По всем вопросам:</div>
        <PhoneLink className="text-2xl font-semibold leading-8 my-2" />
        <div className="leading-4">пн-вс с 00:01 — 23:59</div>
      </div>
    </div>
  </>
);
