/* eslint-disable max-len */
import * as React from 'react';
import { navigate } from 'gatsby';
import { Actions } from 'store';
import { ProductModal } from 'modals/Product';
import { useGlobal } from './hooks';
import { Modal } from './Modal';

const modals: { [key in string]: any } = {
  product: ProductModal,
};

export const ModalLogic = () => {
  const [modal, setModal] = useGlobal(
    (s) => s.modal,
    (a) => a.setModal,
  );

  if (!modal) {
    return null;
  }

  const Content = modals[modal];

  if (!Content) {
    return null;
  }

  const onClose = () => {
    setModal(null);
    window.history.replaceState(null, '', '?modal=null');
  };

  return (
    <Modal onClose={onClose}>
      <Content setModal={setModal} onClose={onClose} />
    </Modal>
  );
};

type ButtonProps = {
  readonly to: string;
  readonly qp?: string;
};

export const ModalButton = ({ to, qp, ...props }: ButtonProps & React.HTMLProps<HTMLDivElement>) => {
  const [, setModal] = useGlobal(
    () => null,
    (a) => a.setModal,
  );

  const onClick = () => {
    setModal(to);
    window.history.replaceState(null, '', `?modal=${to}${qp}`);
  };

  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/jsx-props-no-spreading
  return <div onClick={onClick} {...props} />;
};

ModalButton.defaultProps = {
  qp: '',
};

type LinkProps = {
  readonly to: string;
  readonly setModal: Actions['setModal'];
};

// eslint-disable-next-line max-len
export const ModalLink = ({ to, setModal, ...props }: LinkProps & React.HTMLProps<HTMLButtonElement>) => {
  const onClick = () => {
    setModal(null);
    navigate(to, { replace: true });
  };
  /* eslint-disable react/jsx-props-no-spreading */
  // @ts-ignore
  return <button type="button" onClick={onClick} {...props} />;
};
