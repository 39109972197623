import * as React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useMatch } from '@reach/router';
import logo from 'images/logo.png';
import logoRetina from 'images/logo@2x.png';
import BurgerIcon from './icons/Burger';
import { MobileMenu } from './MobileMenu';
import s from './Header.module.css';

export const Header = () => {
  const [menuShown, showMenu] = React.useState(false);
  const isIndex = useMatch('/');

  const handleShowMenu = () => showMenu(true);
  const handleCloseMenu = () => showMenu(false);

  return (
    <>
      <header
        className={cn(
          'border-b transition-colors duration-300 ease-in',
          isIndex ? 'tr-bg-yellow-1 border-b tr-border-yellow-2' : 'bg-white border-b tr-border-gray-2',
        )}
      >
        <div className={s.container}>
          <Link to="/" className="transition-opacity duration-300 ease-in hover:opacity-75 focus:opacity-75">
            <img src={logo} srcSet={`${logoRetina} 2x`} alt="Tree Ripe" className={s.logoImg} />
          </Link>
          <div
            className={cn(
              'w-px h-10 mx-8 transition-colors duration-300 ease-in mlg:h-8 mlg:mx-6 mmd:h-6 mmd:mx-4 msm:hidden',
              isIndex ? 'tr-bg-yellow-2' : 'tr-bg-gray-1',
            )}
          />
          <div className="leading-5 mr-auto mlg:text-sm mlg:leading-4 mmd:text-xs msm:hidden">
            Свежие овощи и фрукты
            <br />
            премиального качества
          </div>
          <Link to="/catalog" className={s.link}>
            Каталог
          </Link>
          <Link to="/delivery" className={s.link}>
            Доставка
          </Link>
          <Link to="/contacts" className={cn(s.link, 'mr-0')}>
            Контакты
          </Link>
          <button type="button" onClick={handleShowMenu} className={s.burgerButton}>
            <BurgerIcon className="w-6 h-6" />
          </button>
        </div>
      </header>
      <MobileMenu shown={menuShown} onClose={handleCloseMenu} />
    </>
  );
};
