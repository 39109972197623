import * as React from 'react';
import { Link } from 'gatsby';
import logo from 'images/logo.png';
import logoRetina from 'images/logo@2x.png';
import { PhoneLink } from './PhoneLink';
import s from './Footer.module.css';

export const Footer = () => (
  <footer className="container pt-24 pb-16 mlg:pt-20 mmd:pt-12 mmd:pb-10 msm:py-10">
    <div className="flex items-center justify-between border-b tr-border-gray-1 pb-8 mb-8 mmd:pb-6 mmd:mb-6 msm:flex-col msm:items-stretch">
      <div>
        <Link to="/" className={s.logo}>
          <img src={logo} srcSet={`${logoRetina} 2x`} alt="" />
        </Link>
        <div className="flex msm:border-b msm:tr-border-gray-1 msm:pb-6 msm:mb-6">
          <Link
            to="/catalog"
            className="font-semibold leading-5 transition-opacity duration-300 hover:opacity-75 mr-6 mmd:text-sm"
          >
            Каталог
          </Link>
          <Link
            to="/delivery"
            className="font-semibold leading-5 transition-opacity duration-300 hover:opacity-75 mr-6 mmd:text-sm"
          >
            Доставка
          </Link>
          <Link
            to="/contacts"
            className="font-semibold leading-5 transition-opacity duration-300 hover:opacity-75 mmd:text-sm"
          >
            Контакты
          </Link>
        </div>
      </div>
      <div className="text-right msm:text-left">
        <div className="font-semibold leading-5 mb-2 mlg:text-sm mlg:leading-4">
          Остались вопросы? Звоните или пишите
        </div>
        <PhoneLink className={s.phone} />
        <div className="leading-5 mlg:text-sm mlg:leading-4">
          Режим работы: понедельник - воскресенье с 09:00 - 18:00
        </div>
      </div>
    </div>
    <div className="flex items-center justify-between msm:flex-col msm:items-start">
      <div className="leading-5 mlg:text-sm mlg:leading-4 mmd:text-xs">
        © {new Date().getFullYear()}
        , Tree Ripe. OOO Трирайп
        <br />
        Все права защищены.
      </div>
      <div className={s.privacy}>
        Оставляя заявку на сайте treeripe.ru, Вы даете свое согласие на{' '}
        <Link to="/privacy" className={s.privacyLink}>
          обработку персональных данных
        </Link>{' '}
        и соглашаетесь с{' '}
        <Link to="/privacy" className={s.privacyLink}>
          политикой конфиденциальности
        </Link>
      </div>
    </div>
  </footer>
);
