export type CountryCode = 'GR' | 'UZ' | 'AZ' | 'AM' | 'RU';

type Data = {
  [key in CountryCode]: {
    name: string;
    iconName: string;
  };
};

export const countriesData: Data = {
  UZ: {
    name: 'Узбекистан',
    iconName: 'uzbekistn',
  },
  GR: {
    name: 'Грузия',
    iconName: 'georgia',
  },
  AZ: {
    name: 'Азербайджан',
    iconName: 'azerbaijan',
  },
  AM: {
    name: 'Армения',
    iconName: 'armenia',
  },
  RU: {
    name: 'Россия',
    iconName: 'russia',
  },
};
