import { Store } from 'use-global-hook';
import { getQueryParams } from 'utils';

export type State = {
  readonly modal: null | string;
};

export type Actions = {
  setModal: (name: State['modal']) => void;
};

const rawModal = getQueryParams().modal;

export const initialState: State = {
  modal: typeof rawModal === 'string' ? rawModal : null,
};

export const actions = {
  setModal: (store: Store<State, Actions>, modal: State['modal']) => {
    store.setState({ ...store.state, modal });
  },
};
