import { CountryCode } from 'data/countries';

export type Product = {
  readonly status: 'p' | 'd';
  readonly countryCode: CountryCode;
  readonly name: string;
  readonly shortDescription: string;
  readonly slug: string;
  readonly description: string;
  readonly beneficialFeatures: string[];
  readonly composition: {
    readonly energyValue: string;
    readonly proteins: string;
    readonly fats: string;
    readonly carbohydrates: string;
  } | null;
  readonly storage: {
    readonly expirationDate: string;
    readonly temperature: string;
  };
};

export const productsData: Product[] = [
  {
    status: 'p',
    countryCode: 'UZ',
    name: 'Гранат',
    shortDescription: 'Яркий кисло-сладкий вкус крупных, прозрачных зерен.',
    slug: 'granat',
    description:
      'Семена спелого плода похожи на рубины - крупные, прозрачные, насыщенного красно-бордового цвета. Яркий кисло-сладкий вкус зерен широко используется в кулинарии - их добавляют в закуски, соусы, маринады для мяса и птицы, десерты, напитки, сиропы. В народной медицине высоко ценится сок граната, отвары из кожуры и косточек. Врачи часто рекомендуют гранат беременным женщинам для восполнения дефицита железа и в роли природного поливитаминного комплекса.',
    beneficialFeatures: [
      'Содержит витамины: А, В1, В2, В5, В6, С, Е, РР, дубильные вещества, антиоксиданты, танин, незаменимые аминокислоты. Богат калием, кальцием, натрием, железом, фосфором и йодом;        ',
      'Благотворно влияет на нервную, сердечно-сосудистую, пищеварительную системы. Нормализует водный баланс. Обладает антибактериальными и противораковыми свойствами;',
      'Укрепляет иммунитет, эффективен при простудных заболеваниях. Восстанавливает организм истощенный стрессами, физическими нагрузками или заболеванием, очищает от токсинов.',
    ],
    composition: {
      energyValue: '57,80 ККал',
      proteins: '0,7 г',
      fats: '0,22 г',
      carbohydrates: '13,19 г',
    },
    storage: {
      expirationDate: '5 дней',
      temperature: '2 - 6 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'UZ',
    name: 'Хурма Королёк',
    shortDescription: 'Темный, почти шоколадный оттенок, насыщенный сладкий вкус.',
    slug: 'hurma-korolyok',
    description:
      'От обычной хурмы «Королек» отличается своим темным, почти шоколадным оттенком, а также, гораздо более сладким вкусом. Мякоть применяют в приготовлении фруктовых салатов, пирогов, пудингов, желе, муссов, джемов, мармеладов. Хурму употребляют в свежем и сушеном виде, с мороженым или йогуртом. Из неё даже производят слабоалкогольные напитки: патоку, сидр, вино.',
    beneficialFeatures: [
      'Содержит витамины А, С и P, органические кислоты, дубильные вещества, антиоксиданты, пектин и танин. Богата железом, кальцием, медью, марганцем, калием и йодом;',
      'Тонизирует, повышает работоспособность. Сытная, при этом низкокалорийная, выводит излишки жидкости из организма. Рекомендуется в диетическом питании;',
      'Способствует укреплению нервной и сердечно-сосудистой систем, улучшает работу печени, почек, щитовидной железы, половых органов, обладает бактерицидными свойствами.',
    ],
    composition: {
      energyValue: '49 ККал',
      proteins: '0,8 г',
      fats: '0,3 г',
      carbohydrates: '9,6 г',
    },
    storage: {
      expirationDate: '5 дней',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'AZ',
    name: 'Хурма Ромашка',
    shortDescription: 'Сладкая хурма сорта "Ромашка" из Азербайджана.',
    slug: 'hurma-romashka',
    description:
      'Сладкая хурма сорта "Ромашка" из Азербайджана. Мякоть коричневеет при созревании, почти как у шоколадной хурмы. Имеется несколько слегка вытянутых косточек, но не таких, как у "Королька".',
    beneficialFeatures: [
      'Содержит витамины А, С и P, органические кислоты, дубильные вещества, антиоксиданты, пектин и танин. Богата железом, кальцием, медью, марганцем, калием и йодом;        ',
      'Тонизирует, повышает работоспособность. Сытная, при этом низкокалорийная, выводит излишки жидкости из организма. Рекомендуется в диетическом питании;        ',
      'Способствует укреплению нервной и сердечно-сосудистой систем, улучшает работу печени, почек, щитовидной железы, половых органов, обладает бактерицидными свойствами.',
    ],
    composition: {
      energyValue: '67 ККал',
      proteins: '0,5 г',
      fats: '0,4 г',
      carbohydrates: '15,3 г',
    },
    storage: {
      expirationDate: '5 дней',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'AZ',
    name: 'Хурма Бычье сердце',
    shortDescription: 'Спелые плоды скрывающие сочную, невероятно вкусную мякоть.',
    slug: 'hurma-byche-serdce',
    description:
      'Хурма не просто фрукт, а настоящая "пища богов". Спелые ярко-оранжевые плоды скрывают внутри сочную, невероятно вкусную мякоть. Вкушать плод вкуснее всего в свежем виде - гурманы же могут добавить хурму в салат, мороженное или создать из нее домашний сидр.',
    beneficialFeatures: [
      'Содержит витамины А, С и P, органические кислоты, дубильные вещества, антиоксиданты, пектин и танин. Богата железом, кальцием, медью, марганцем, калием и йодом;',
      'Тонизирует, повышает работоспособность. Сытная, при этом низкокалорийная, выводит излишки жидкости из организма. Рекомендуется в диетическом питании;        ',
      'Способствует укреплению нервной и сердечно-сосудистой систем, улучшает работу печени, почек, щитовидной железы, половых органов, обладает бактерицидными свойствами.',
    ],
    composition: {
      energyValue: '49 ККал',
      proteins: '0,8 г',
      fats: '0,3 г',
      carbohydrates: '9,6 г',
    },
    storage: {
      expirationDate: '5 дней',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'UZ',
    name: 'Лимон',
    shortDescription: 'Ароматный цитрус с ярко выраженным кислым вкусом.',
    slug: 'limon',
    description:
      'Этот ароматный цитрус с ярко выраженным кислым вкусом незаменим в вашем холодильнике. По мнению йогов, для сохранения крепкого здоровья, лимоны нужно употреблять ежедневно. Популярны в приготовлении огромного количества напитков, салатов, рыбных и мясных блюд, соусов, а также десертов. В кулинарии широко используют не только сок и мякоть этих южных фруктов, но и цедру, лимонную кислоту и эфирное масло. Прекрасно сочетаются практически с любыми продуктами.',
    beneficialFeatures: [
      'Содержат витамины А, В1, В2, В3, В5, В6, С, Е, эфирные масла, органические кислоты, пектины, фитонциды, клетчатку. Богаты калием, кальцием, натрием, магнием, железом;        ',
      'Обладают антисептическими, противомикробными, потогонными и очищающими свойствами. Укрепляют здоровье дыхательных путей, сердца и сосудов, желудочно-кишечного тракта и почек;        ',
      'Улучшают иммунитет. Повышают работоспособность, концентрацию внимания и мозговую активность. Используется в профилактике кариеса и болезней десен, снимает зубную боль.',
    ],
    composition: {
      energyValue: '34 ККал',
      proteins: '0,9 г',
      fats: '0,1 г',
      carbohydrates: '3 г',
    },
    storage: {
      expirationDate: '5 дней',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'AZ',
    name: 'Мандарины',
    shortDescription: 'Всеми любимые кисло-сладкие мандарины из Абхазии.',
    slug: 'mandariny',
    description:
      'Созрели всеми любимые кисло-сладкие мандарины из Абхазии! Плоды среднего размера легко очищаются от шкурки. Мандарины отлично подходят для перекуса, добавления в различные соусы, выпечку или горячие блюда.',
    beneficialFeatures: [
      'Содержат витамины B1, B2, С, D, K, органические кислоты, пектины, клетчатку, эфирные масла, антиоксиданты. Богаты калием, кальцием, магнием, фосфором, натрием, железом;        ',
      'Стимулируют обмен веществ, поднимают настроение и общий тонус организма. Подходят для профилактики авитаминоза и отёков, укрепляют сердечно-сосудистую систему;',
      'Помогают при лечении простуды, болезней органов дыхания, пищеварения, желудочно-кишечного тракта, кожных и грибковых инфекций.',
    ],
    composition: {
      energyValue: '38 ККал',
      proteins: '0,8 г',
      fats: '0,2 г',
      carbohydrates: '7,5 г',
    },
    storage: {
      expirationDate: '5 дней',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'AZ',
    name: 'Голубика',
    shortDescription: 'Вкусная, ароматная ягода, обладающая массой полезных свойств.',
    slug: 'golubika',
    description:
      'Голубика – синяя ягода с белесым налетом, вкусная, ароматная, обладающая массой полезных свойств. Голубика используется как в свежем, так и в переработанном виде. Из нее готовят варенье, пастилу, компот, желе, кисель, мусс, вино, квас, сырое варенье (протертая с сахаром ягода).',
    beneficialFeatures: [
      'Содержит провитамин А, каротиноиды, витамины В1, В2, РР, аскорбиновую кислоту, витамины К и Р;',
      'В состав голубики входят 6 незаменимых аминокислот, дубильные вещества, пектины;',
      'Особой ценностью обладают антоцианы, усиливающие действие антиоксидантов.',
    ],
    composition: null,
    storage: {
      expirationDate: '3 дня',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'AZ',
    name: 'Помидоры розовые',
    shortDescription: 'Аппетитные розовые помидоры.',
    slug: 'pomidor',
    description:
      'Аппетитные розовые помидоры среднего размера имеют округлую форму и яркую окраску. Помидоры более сладкие, чем обычные, это придает им насыщенный приятный вкус. Помидоры разнообразят зеленый салат и серые будни! Яркие помидорки считаются лечебными, ведь содержат много ликопина и бета-каротина.',
    beneficialFeatures: [
      'Содержат большое количество ликопина, что препятствует возникновению сердечно-сосудистых заболеваний и уменьшает риск развития раковых опухолей;',
      'Богаты бета-каротином;',
      'Помидоры нормализуют работу кишечника.',
    ],
    composition: {
      energyValue: '18 ККал',
      proteins: '0,88 г',
      fats: '0,20 г',
      carbohydrates: '3,89 г',
    },
    storage: {
      expirationDate: '3 дня',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'UZ',
    name: 'Кинза',
    shortDescription: 'Бесподобная зелень кинзы.',
    slug: 'kinza',
    description:
      'Зелень кинзы бесподобно подходит к мясу, луку и овощам — ко всему, что можно приготовить из свежих продуктов в летний или зимний день. Кстати, сама кинза должна быть свежей — засушенной она почти не употребляется.',
    beneficialFeatures: [
      'Содержит витамины группы С, В, РР;',
      'Содержит рутин, каротин, пектин, ароматические масла, аскорбиновую кислоту, макро и микроэлементы;',
      'Помогают усваивать тяжелую, крахмальную пищу и корнеплоды, выводят шлаки.',
    ],
    composition: {
      energyValue: '23 ККал',
      proteins: '2,13 г',
      fats: '0,52 г',
      carbohydrates: '0,87 г',
    },
    storage: {
      expirationDate: '3 дня',
      temperature: '2 - 6 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'UZ',
    name: 'Щавель',
    shortDescription: 'Богатый весенними витаминами щавель.',
    slug: 'shavel',
    description:
      'Из щавеля готовят весенние витаминные зеленые щи, очень вкусную начинку для пирогов, листья заготавливают на зиму, делают пюре, салаты, добавляют в омлеты и даже варят кисель.',
    beneficialFeatures: [
      'Улучшает пищеварение, функции печени и желчного пузыря;',
      'Зеленые части и плоды щавеля обладают вяжущими, обезболивающими, противовоспалительными и антитоксическими свойствами;',
      'Благодаря высокому содержанию витамина C, усиливается всасывание железа, и, как следствие, поднимается гемоглобин в крови.',
    ],
    composition: {
      energyValue: '22 ККал',
      proteins: '1,5 г',
      fats: '0,3 г',
      carbohydrates: '2,9 г',
    },
    storage: {
      expirationDate: '2 дня',
      temperature: '0 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'UZ',
    name: 'Салат латук листовой',
    shortDescription: 'Вкуснейший латук листовой.',
    slug: 'salatLatuk',
    description:
      'Все полезные свойства салата находятся в молочном соке или алкалоиде лактуцине. Именно он придает листьям пикантный привкус и является природным успокаивающим веществом.\n' +
      'Салат латук является не только низкокалорийным и диетическим растением (всего лишь 15 Ккал на 100 г), но и быстро создает чувство сытости во время обеда из-за большого содержания клетчатки.',
    beneficialFeatures: [
      'Улучшает мозговую деятельность;',
      'Имеет большое содержание клетчатки',
      'Содержит витамин С, ряд витаминов группы В (В1, В2, В5, В6 В9, РР).',
    ],
    composition: {
      energyValue: '15 ККал',
      proteins: '1,77 г',
      fats: '0,32 г',
      carbohydrates: '1,84 г',
    },
    storage: {
      expirationDate: '3 дня',
      temperature: '0 - 2 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'UZ',
    name: 'Руккола',
    shortDescription: 'Руккола включает множество полезных веществ',
    slug: 'rykkola',
    description:
      'Руккола — популярное в наши дни салатное растение, обладающее своеобразным пикантным вкусом. Впервые об этом растении упоминается еще во времена античности. Руколу добавляют в пиццы, ризотто, салаты и блюда из пасты, её используют вместо базилика или в смеси с ним в соусе песто, подают к мясу и украшают ей множество различных блюд.',
    beneficialFeatures: [
      'Содержит витамины группы В, витамин С, витамин А, Е, К;',
      'Отлично тонизирует организм;',
      'Содержит минералы: калий, кальций, магний, фосфор, натрий, медь, марганец, цинк, селен, железо, йод.',
    ],
    composition: {
      energyValue: '24,5 ККал',
      proteins: '2,58 г',
      fats: '0,66 г',
      carbohydrates: '2,05 г',
    },
    storage: {
      expirationDate: '2 дня',
      temperature: '2 - 6 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'UZ',
    name: 'Огурцы Бакинские',
    shortDescription: 'Лучшие бакинские огурцы',
    slug: 'ogyrci',
    description:
      'Сочный водянистый овощ, знаменитый своими диетическими свойствами. Широко используется в кулинарии в составе салатов, а также различных солений и маринадов.',
    beneficialFeatures: [
      'На 95% состоит из воды, отличается низкой калорийностью (всего 14 ккал на 100г) и высоким содержанием клетчатки. Содержит уникальную тартроновую кислоту, замедляющую процесс преобразования углеводов в жиры;',
      'Содержит витамины: А, К, С, В1, В2, В5, В6, Н, РР, фолиевую кислоту, холин. Минералы: калий, йод, алюминий, медь, фтор, марганец, кальций, фосфор и др;',
      'Мощный абсорбент выводит из организма шлаки и токсины, обладает мочегонным эффектом, улучшает состояние зубов и дёсен.',
    ],
    composition: {
      energyValue: '14 Ккал',
      proteins: '0,8 г',
      fats: '0,1 г',
      carbohydrates: '2,5 г',
    },
    storage: {
      expirationDate: '7 дней',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'AZ',
    name: 'Лук',
    shortDescription: 'Богатый Витаминами лук',
    slug: 'lyk',
    description:
      'Целая аптека в одном продукте. В свежем виде этот овощ обладает резким вкусом и запахом, однако в кулинарии придаёт готовым яствам пикантность и аромат. Используют в большинстве блюд: супах, мясных и рыбных блюдах, шашлыках и барбекю. Особенно популярен в сочетании с мясом. При употреблении в свежем виде его обычно маринуют в уксусе или лимонном соке для смягчения вкуса.',
    beneficialFeatures: [
      'Богатый источник эфирных масел, антиоксидантов, витаминов С и группы В, а также таких минералов, как железо, калий, кальций, магний, сера и др;',
      'Природный антибиотик. Активирует обмен веществ, способствует кроветворению и очищению крови, выводит из организма лишнюю жидкость;',
      'При термической обработке сохраняет противовоспалительные и противомикробные свойства практически в полном объёме.',
    ],
    composition: {
      energyValue: '41 Ккал',
      proteins: '1,4 г',
      fats: '0,2 г',
      carbohydrates: '8,2 г',
    },
    storage: {
      expirationDate: '30 дней',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'AZ',
    name: 'Картофель',
    shortDescription: 'Мягкий и питательный картофель',
    slug: 'cartofel',
    description:
      'Твердые клубни овальной формы. Обладают мягким вкусом и высокой питательностью. Длительное время сохраняют свои вкусовые характеристики и рекомендуются к регулярному использованию в пищу. Хорошо подходят для отваривания, жарки, тушения и запекания.',
    beneficialFeatures: [
      'Богат витаминами С, D, PP, E, U, группы В, а также фосфором, железом, йодом, магнием, и др. минералами, клетчаткой, полноценными растительными белками, аминокислотами;',
      'Картофельный крахмал снижает содержание холестерина в печени и сыворотке крови. Высокое содержание калия выводит излишки жидкости из организма;',
      'Нейтрализует излишки кислот, нормализует обмен веществ, работу сердца и ЖКТ. Улучшает работу печени.',
    ],
    composition: {
      energyValue: '77 Ккал',
      proteins: '1,9 г',
      fats: '0,4 г',
      carbohydrates: '16,6 г',
    },
    storage: {
      expirationDate: '7 дней',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'UZ',
    name: 'Капуста',
    shortDescription: 'Богатая минералами капуста',
    slug: 'capysta',
    description:
      'Листья капусты довольно обширно используются в кулинарии: их добавляют почти во все супы, тушат, квасят и добавляют в салаты. Капуста способна чистить организм и отлично подходит в качестве диетического блюда.',
    beneficialFeatures: [
      'Обладает высокими диетическими свойствами. Содержит всего 27 ккал на 100г продукта;',
      'Чрезвычайно богата витамином С (богаче цитрусовых) и редким витамином U. Также содержит витамины группы В, калий, кальций, фосфор, цинк, железо и другие минералы;',
      'Стимулирует обменные процессы.',
    ],
    composition: {
      energyValue: '25 Ккал',
      proteins: '1,3 г',
      fats: '0,1 г',
      carbohydrates: '5,8 г',
    },
    storage: {
      expirationDate: '10 дней',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'UZ',
    name: 'Салат Лолло Росса',
    shortDescription: 'Изысканный салат Лолло Росса',
    slug: 'lollorossa',
    description:
      'Салат имеет очень насыщенный и слегка горьковатый ореховый вкус. Листья мягкие и способствуют приданию объема салату. Салат Лолло Росса применяется как сам по себе, так и в смеси с пряными салатами. Прекрасно подходит для горячих закусок, соусов, запеченных овощей и жареного мяса.',
    beneficialFeatures: [
      'Салат Лолло Росса является довольно легким, он с легкостью усваивается желудком;',
      'Богат кальцием, цинком, кобальтом, йодом, магнием и фосфором;',
      'В нем есть и железо, способствующее укреплению иммунитета и устраняющее усталость.',
    ],
    composition: {
      energyValue: '15,75 Ккал',
      proteins: '1,40 г',
      fats: '0,20 г',
      carbohydrates: '2,15 г',
    },
    storage: {
      expirationDate: '3 дня',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'UZ',
    name: 'Мята',
    shortDescription: 'Мы предлагаем вам свежую мяту, выращенную в экологически чистом районе.',
    slug: 'myata',
    description:
      'Мята — очень знаменитая зелень! Известна тем, что часто используется для приготовления различных блюд, например, ее добавляют в соусы для баранины английской кухни. Применяется для усиления вкуса и аромата напитков из томата, добавляется в овощные и фруктовые салаты. Во многих итальянских блюдах она используется, как пряность. Очень часто её добавляют в пряники. Листочки мяты улучшают вкус мясных блюд. Также всегда можно просто заварить мяту или украсить ей десерт.',
    beneficialFeatures: [
      'Улучшает мозговую деятельность;',
      'Нормализует сон: ее активно применяют для лечения нарушений сна и бессонницы;',
      'Содержит каротин и витамин С, ряд витаминов группы В (В1, В2, В5, В6 В9, РР).',
    ],
    composition: {
      energyValue: '44 ККал',
      proteins: '3,47 г',
      fats: '0,73 г',
      carbohydrates: '11,62 г',
    },
    storage: {
      expirationDate: '2 дня',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'RU',
    name: 'Кабачки',
    shortDescription: 'Мы предлагаем вам кабачок из экологически чистой местности.',
    slug: 'kabachek',
    description:
      'Кабачок — это однолетнее травянистое растение из семейства тыквенных (разновидность тыквы обыкновенной). Кабачки имеют весьма широкий спектр применения в кулинарии: их жарят, пекут, маринуют, употребляют сырыми в салатах, готовят из них кабачковую икру, оладьи, овощные и мясные рагу, супы и даже делают варенье. Кабачок — это овощ, из которого можно приготовить практически все: от оладьев до варенья.',
    beneficialFeatures: [
      'Высокое содержание витаминов группы C и B;',
      'Содержат много различных микроэлементов — железа, меди, фосфора, калия, магния и натрия;',
      'Подходит для детского питания, так как он малоаллергенен.',
    ],
    composition: {
      energyValue: '24 кКал',
      proteins: '0,6 г',
      fats: '0,3 г',
      carbohydrates: '4,6 г',
    },
    storage: {
      expirationDate: '7 дней',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'UZ',
    name: 'Розмарин',
    shortDescription: 'Мы предлагаем вам натуральный и свежий розмарин без использования пестицидов.',
    slug: 'rozmarin',
    description:
      'Розмарин — популярная приправа, яркий хвойный аромат делает эту траву легкоузнаваемой и любимой кулинарами со всего мира. Особенно хорошо сочетается эта пряность с рыбой, мясным фаршем, жареной птицей, грибами. Ее также добавляют к овощным блюдам и супам. Для придания пряного вкуса розмарин добавляют к мягким сырам, мясу дичи, к баранине, сдобному тесту и картофелю.',
    beneficialFeatures: [
      'Улучшает циркуляцию крови, улучшает кровоснабжение головного мозга, благодаря чему повышает внимание, улучшает память, является стимулятором мозговой активности;',
      'Регулярно употребляя пищу с розмарином, можно укрепить сердечно-сосудистую систему, расширить сосуды и улучшить аппетит;',
      'Лечебные свойства розмарина помогают при проблемах с пищеварением, коликах в желудке.',
    ],
    composition: {
      energyValue: '131 кКал',
      proteins: '3,3 г',
      fats: '5,9 г',
      carbohydrates: '20,7 г',
    },
    storage: {
      expirationDate: '3 дня',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'UZ',
    name: 'Салат Айсберг',
    shortDescription: 'Мы предлагаем вам салат без использования пестицидов, синтетических удобрений и ГМО.',
    slug: 'aisberg',
    description:
      'Салат едят, в основном, в сыром виде. Добавляют его в различные соусы, закуски, овощные салаты, блюда из птицы, рыбы, овощей.',
    beneficialFeatures: [
      'Систематическое употребление салата положительно влияет на сердечную мышцу и сосуды, уровень гемоглобина, артериальное давление, водно-солевой баланс;',
      'Cодержит витамины группы В, А, С, Е, К, РР, железо, кальций, калий, магний, селен, природные аминокислоты, незаменимые для организма человека;',
      'Натрия в салате на 60% больше, чем калия, что важно для некоторых диет.',
    ],
    composition: {
      energyValue: '14 кКал',
      proteins: '0,9 г',
      fats: '0,1 г',
      carbohydrates: '1,8 г',
    },
    storage: {
      expirationDate: '2 дня',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'UZ',
    name: 'Редис',
    shortDescription: 'Мы предлагаем вам редис из экологически чистой местности.',
    slug: 'rediska',
    description:
      'Редис — вкусный корнеплод, из которого готовят салаты и холодные супы. Даже листья редиса годны для приготовления вкусных блюд. Несмотря на то, что редис повышает желание кушать, он активно используется для приготовления диетических блюд. В результате его употребления вырабатываются ферменты, повышающие функцию переработки еды и позволяющие сжигать жиры.',
    beneficialFeatures: [
      'Содержит витамины: С, В1, В2, В5, В6, В9, Е, PP, E, C, пектин, клетчатку, органические кислоты, горчичное масло. Богат калием, фосфором, кальцием, магнием, железом;',
      'Обладает желчегонным, мочегонным, слабительным, антисептическим свойствами. Благотворно влияет на кишечник, сердечно-сосудистую, пищеварительную системы;',
      'Возбуждает аппетит, нормализует обмен веществ, укрепляет иммунитет. Выводит холестерин и токсины. Помогает сохранить молодость и стройность.',
    ],
    composition: {
      energyValue: '19 кКал',
      proteins: '1,2 г',
      fats: '0,1 г',
      carbohydrates: '3,4 г',
    },
    storage: {
      expirationDate: '5 дней',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'UZ',
    name: 'Черешня',
    shortDescription: 'Вкусная и душистая черешня',
    slug: 'cheresnya-uz',
    description:
      'Черешня – прекрасный и полезный для ежедневного употребления продукт. Ягода богата антиоксидантами, антоцианом, калием, витамином B и клетчаткой. Черешня благоприятно влияет на память и работу мозга в целом, на сердечно-сосудистую систему, регулирует уровень кровяного давления и противодействует формированию тромбов, а также улучшает работу нервной и пищеварительной систем, улучшает состояние ногтей и волос.\n',
    beneficialFeatures: [
      'Черешня богата медью, железом, витаминами С и РР и флавоноидами;',
      'Помогает улучшить состояние сердечно-сосудистой и нервной систем;',
      'Повышает иммунитет и общее состояние организма;',
    ],
    composition: {
      energyValue: '52 кКал',
      proteins: '10,6 г',
      fats: '0,2 г',
      carbohydrates: '0,8 г',
    },
    storage: {
      expirationDate: '7-10 дней',
      temperature: '0 - 2 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'UZ',
    name: 'Абрикосы',
    shortDescription: 'Сочные, сладкие, тающие во рту абрикосы',
    slug: 'abricos-uz',
    description:
      'Абрикос – небольшой и очень ароматный родственник персика. Плоды имеют желтовато-оранжевый цвет и приятный сладкий вкус. В состав абрикосов входит большое количество пектиновых веществ, известных своей способностью выводить токсины. Абрикосы богаты витаминами, минералами, легко усваиваемой фруктозой. Кроме того, свежие плоды низкокалорийны. Интересно отметить тот факт, что абрикос является основным продуктом питания для небольшой группы людей в Гималаях, которые известны своим долголетием, крепким здоровьем и исключительно вегетарианским питанием.',
    beneficialFeatures: [
      'Содержит витамины А, группы В, С, РР;',
      'Нормализует работу ЖКТ;',
      'Способствует укреплению костей.',
    ],
    composition: {
      energyValue: '48 кКал',
      proteins: '11 г',
      fats: '0,39 г',
      carbohydrates: '1,4 г',
    },
    storage: {
      expirationDate: '10 дней',
      temperature: '0 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'UZ',
    name: 'Слива',
    shortDescription: 'Лучший сорт сливы для вашего стола',
    slug: 'sliva-uz',
    description:
      'Упоминания о благородной сливе начинаются в Египте IV-VI вв. до \n' +
      'н. э. Уже в те времена она широко употреблялась населением как в свежем виде, так и в разнообразных домашних заготовках.\n' +
      'Кисло-сладкая и сочная — она прекрасно подойдёт для приготовления лёгких десертов, пирогов и в качестве перекуса.\n',
    beneficialFeatures: [
      'Легко усваивается;',
      'Обладает высокими диетическими свойствами. Содержит всего 42 ккал на 100г продукта;',
      'Богата белками, углеводами, пищевыми волокнами, витаминами P, C, группы В, калием, кальцием, магнием, фосфором, антиоксидантами и органическими кислотами.',
    ],
    composition: {
      energyValue: '48 кКал',
      proteins: '11 г',
      fats: '0,5 г',
      carbohydrates: '2,2 г',
    },
    storage: {
      expirationDate: '5 дней',
      temperature: '2 - 6 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'UZ',
    name: 'Нектарины',
    shortDescription: 'Спелые, бархатные нектарины',
    slug: 'nektarin-uz',
    description:
      'Спелые, крупные, полезные нектарины идеально подходят для употребления в свежем виде, а также из них можно приготовить аппетитный джем, варенье, добавить в выпечку или салат. Попробуйте!',
    beneficialFeatures: [
      'Нектарины богаты витаминами А и С;',
      'Содержат калий, фосфор, аскорбиновую кислоту;',
      'Пектиновые вещества, содержащиеся в нектаринах, делают эти плоды отличным профилактическим средством против раковых заболеваний.',
    ],
    composition: {
      energyValue: '44 кКал',
      proteins: '11 г',
      fats: '0,32 г',
      carbohydrates: '1,1 г',
    },
    storage: {
      expirationDate: '5 дней',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'AM',
    name: 'Фасоль',
    shortDescription: 'Богатая белками фасоль',
    slug: 'fasol-am',
    description:
      'Фасоль - прекрасный лёгкий гарнир. Хороша она также в качестве компонента овощных супов или как начинка для пирога. Фасоль очищает желудок и дарит полезности:)',
    beneficialFeatures: [
      'Диетологи относят ее к одному из 10 самых полезных продуктов для здоровья человека;',
      'Фасоль обладает успокаивающими свойствами, так, употребление в пищу фасолевых блюд, оказывает благотворное действие на нервную систему;',
      'Фасоль очень богата витамином С (гораздо больше чем в персиках, сливах или абрикосах), а также имеются следующие витамины: РР, В1, В2, В3, В6, Е',
    ],
    composition: {
      energyValue: '350 кКал',
      proteins: '18,9 г',
      fats: '2 г',
      carbohydrates: '63,4 г',
    },
    storage: {
      expirationDate: '7 дней',
      temperature: '2 - 4 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'AZ',
    name: 'Клубника',
    shortDescription: 'Сочная, с небольшой кислинкой на вкус',
    slug: 'klybnika-az',
    description:
      'Сочная, с небольшой кислинкой на вкус, клубника зарядит вас летним настроением даже хмурой осенью или зимой. Клубника полезна для всего организма, так как содержит множество полезных витаминов и элементов.',
    beneficialFeatures: [
      'Богатый источник витамина С;',
      'Содержит витамины А, В1, В2, Е, К и РР;',
      'Клубника полезна для сердечно-сосудистой и нервной системы, для печени, почек и желудочно-кишечного тракта.',
    ],
    composition: {
      energyValue: '33 кКал',
      proteins: '0,67 г',
      fats: '0,3 г',
      carbohydrates: '63,4 г',
    },
    storage: {
      expirationDate: '3 дней',
      temperature: '2 °C',
    },
  },
  {
    status: 'p',
    countryCode: 'RU',
    name: 'Баклажаны',
    shortDescription: 'Отлично подходит для сбалансированного питания',
    slug: 'baklajan-ru',
    description:
      'Этот южный плод необычного фиолетового цвета нашёл своё применение и всенародную любовь во многих кухнях мира. На Востоке его называют «овощем долголетия» и рекомендуют для укрепления здоровья пожилых людей. В кулинарии его ценят за универсальность, уникальный насыщенный вкус и способность впитывать в себя различные соусы. Как баклажаны только не готовят: варят, жарят, запекают, тушат, готовят на гриле, используют для баклажанных салатов и икры.',
    beneficialFeatures: [
      'Обладают высокими диетическими свойствами. Содержат всего 24 ккал на 100г продукта;',
      'Содержат витамины В1, В2, РР, каротин, калий, натрий, кальций, магний, фосфор и железо;',
      'Нормализуют водно-солевой и липидный обмены, выводят соли мочевой кислоты и излишки холестерина, укрепляют сердечно-сосудистую систему.',
    ],
    composition: {
      energyValue: '24 кКал',
      proteins: '1,2 г',
      fats: '0,1 г',
      carbohydrates: '4,5 г',
    },
    storage: {
      expirationDate: '5 дней',
      temperature: '2 -6 °C',
    },
  },
];
