import qs from 'query-string';

export const getQueryParams = (): ReturnType<typeof qs.parse> => {
  if (typeof window !== 'undefined') {
    return qs.parse(window.location.search);
  }
  return {};
};

const normalizePhone = (val: string) => {
  let nv = val.replace(/[^\d]/g, '');

  if (nv[0] === '8' && nv.length === 11) {
    nv = `7${nv.substr(1)}`;
  }

  return nv;
};

export const validatePhone = (val: string) => {
  const phone = normalizePhone(val);

  if (phone.length !== 11) {
    return 'Проверьте номер телефона. Количество цифр должно быть равно 11';
  }

  if (phone[0] !== '7') {
    return 'Проверьте номер телефона.';
  }

  return true;
};

export const formatPhoneNumber = (phone: string) => {
  const match = phone.match(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/);

  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
  }

  return phone;
};
