/* eslint-disable max-len */
import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Actions } from 'store';
import { getQueryParams } from 'utils';
import { productsData } from 'data/products';
import { countriesData } from 'data/countries';
import { ModalLink } from 'components/ModalLogic';
import { Icon } from 'components/icons';
import s from './Product.module.css';

type Props = {
  readonly setModal: Actions['setModal'];
};

export const ProductModal = ({ setModal }: Props) => {
  const qp = getQueryParams();
  const p = productsData.find((product) => product.name === qp.name);

  if (!p) return null;

  const data = useStaticQuery<GatsbyTypes.ProductQuery>(graphql`
    query ProductQuery {
      allFile(filter: { sourceInstanceName: { eq: "productsImages" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 694) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `);

  const file = data.allFile.nodes.find((f) => f.name === p.slug);

  const country = countriesData[p.countryCode];

  return (
    <div className={s.root}>
      <div className={s.top}>
        <div className={s.title}>{p.name}</div>
        <div className={s.country}>
          <Icon name={country.iconName} className={s.flag} />
          {country.name}
        </div>
      </div>
      {/* @ts-ignore */}
      {file?.childImageSharp?.fluid && <Img fluid={file.childImageSharp.fluid} alt={p.name} className={s.image} />}
      <div className={s.description}>{p.description}</div>
      <div className={s.beneficialFeatures}>
        <div className={s.beneficialFeaturesTitle}>Полезные свойства</div>
        {p.beneficialFeatures.map((f) => (
          <div key={f} className={s.beneficialFeaturesItem}>
            {f}
          </div>
        ))}
      </div>
      <div className={s.info}>
        {p.composition && (
          <div className={s.infoBlock}>
            <div className={s.infoTitle}>Состав</div>
            <div className={s.infoBlockItem}>
              <div>
                Энергетическая ценность <b>{p.composition.energyValue}</b>
              </div>
            </div>
            <div className={s.infoBlockItem}>
              <div>
                Белки <b>{p.composition.proteins}</b>
              </div>
            </div>
            <div className={s.infoBlockItem}>
              <div>
                Жиры <b>{p.composition.fats}</b>
              </div>
            </div>
            <div className={s.infoBlockItem}>
              <div>
                Углеводы <b>{p.composition.carbohydrates}</b>
              </div>
            </div>
          </div>
        )}
        <div className={s.infoBlock}>
          <div className={s.infoTitle}>Хранение</div>
          <div className={s.infoBlockItem}>
            <div>
              Срок годности: <b>{p.storage.expirationDate}</b>
            </div>
          </div>
          <div className={s.infoBlockItem}>
            <div>
              Температура хранения: <b>{p.storage.temperature}</b>
            </div>
          </div>
        </div>
      </div>
      <ModalLink to="/contacts" setModal={setModal} className="button button-small button-yellow self-end">
        Связаться с нами
      </ModalLink>
    </div>
  );
};
