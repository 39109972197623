import * as React from 'react';
import { formatPhoneNumber } from '../utils';

type Props = {
  readonly className?: string;
  readonly showMorePhones?: boolean;
};

type PhoneProps = Pick<Props, 'className'> & {
  phoneNumber: string;
};

const Phone = ({ phoneNumber, className }: PhoneProps) => {
  const phoneNumberLink = `tel:+${phoneNumber}`;

  return (
    <a href={phoneNumberLink} className={className}>
      {formatPhoneNumber(phoneNumber)}
    </a>
  );
};

export const PhoneLink = ({ className, showMorePhones }: Props) => {
  if (!showMorePhones) {
    return <Phone phoneNumber="79251590827" className={className} />;
  }

  return (
    <>
      <Phone phoneNumber="79251590827" className={className} />
      <Phone phoneNumber="74957447687" className={className} />
    </>
  );
};

PhoneLink.defaultProps = {
  className: undefined,
  showMorePhones: undefined,
};
