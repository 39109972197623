import { HTMLAttributes, CSSProperties } from 'react';
import us from './default.module.css';

export interface Props extends HTMLAttributes<HTMLElement> {
  size?: number;
  style?: CSSProperties;
}

export const getProps = (iconClass: string, { className, size, style, ...props }: Props) => {
  const finalStyles: CSSProperties = {
    ...style,
  };

  if (size) {
    finalStyles.width = size;
    finalStyles.height = size;
  }

  return {
    className: `${className || ''} ${us.icon || ''} ${iconClass || ''}`,
    style: finalStyles,
    ...props,
  };
};
