import { createElement, HTMLAttributes } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getProps } from './getProps';

interface Props extends HTMLAttributes<HTMLDivElement> {
  name: string;
}

export const Icon = ({ name, ...props }: Props) => {
  const data = useStaticQuery<GatsbyTypes.IconsQuery>(graphql`
    query IconsQuery {
      allFile {
        nodes {
          name
          publicURL
        }
      }
    }
  `);

  const file = data.allFile.nodes.find((n) => n.name === name);

  if (file) {
    const style = { backgroundImage: `url(${file.publicURL})` };

    return createElement('i', getProps('', { ...props, style }));
  }

  return null;
};
